/**
 * Модель для работы с отчётом "Пребывание в зонах"
 */

import { createModel, ModelConfig } from '@rematch/core';
import { saveAs } from 'file-saver';

import { iRootState } from '../store';
import { IId, IOffice, IArea, IReportNotifications, IEmployeeShort } from './types';
import setStartEndOfDay from '../utils/setStartEndOfDay';

import MapsService from '../services/MapsService';
import UsersService from '../services/UsersService';
import ReportService from '../services/ReportService';

interface IData {
  offices: IOffice[];
  employees: IEmployeeShort[];
  areas: IArea[];
  events: string[];
}

interface IList {
  data: IReportNotifications[] | null;
  pagesCount: number;
}

interface IState {
  data: IData;
  list: IList;
}

const initialState: IState = {
  data: {
    offices: [],
    employees: [],
    areas: [],
    events: []
  },
  list: {
    data: null,
    pagesCount: 0
  }
};

const model: ModelConfig<IState> = {
  state: initialState,
  reducers: {
    setData(state: IState, payload: IData): IState {
      return { ...state, data: { ...state.data, ...payload } };
    },
    resetDataAreas(state: IState): IState {
      const { areas } = initialState.data;

      return { ...state, data: { ...state.data, areas } };
    },
    setList(state: IState, payload: IList): IState {
      return { ...state, list: payload };
    },
    resetList(state: IState): IState {
      return {
        ...state,
        list: {
          data: null,
          pagesCount: 0
        }
      };
    },
    reset(): IState {
      return { ...initialState };
    }
  },
  effects: {
    async getData() {
      try {
        const [
          {
            data: { data: offices }
          },
          { data: employees },
          { data: events }
        ] = await Promise.all([
          MapsService.getOffices(),
          UsersService.getAllEmployeesShort(),
          ReportService.getNotificationEvents()
        ]);

        this.setData({ offices, employees, events });
      } catch (error) {
        return error;
      }
    },
    async getDataAreas(id: IId) {
      try {
        const {
          data: { areas }
        } = await MapsService.get(id);

        this.setData({ areas });
      } catch (error) {
        return error;
      }
    },
    async getList(params, rootState: iRootState) {
      const cloneParams = setStartEndOfDay(params, ['from', 'to']);

      try {
        const { data } = await ReportService.getNotifications(cloneParams);

        this.setList(data);
      } catch (error) {
        return error;
      }
    },
    async exportList(params, rootState: iRootState) {
      const { from, to } = params;

      const cloneParams = setStartEndOfDay(params, ['from', 'to']);

      try {
        const { data } = await ReportService.getCsvNotifications(cloneParams);

        saveAs(data, `Отчет по событиям за период ${from} - ${to}.csv`);
      } catch (error) {
        return error;
      }
    }
  }
};

export const reportNotifications: typeof model = createModel(model);
