import React, { FC } from 'react';
import { ButtonColors } from '@mts-ds/components/dist/types/components/button/enums';

import ContentFilter from '../../../../components_/Content/ContentFilter';
import FormRow from '../../../../components_/Form/FormRow';
import FormCol from '../../../../components_/Form/FormCol';
import Input from '../../../../components_/Fields/AntInput';
import Button from '../../../../components_/Fields/Button';

import { IProps as IListProps } from './';
import { makeStyles } from '@material-ui/core/styles';
import checkFilter from '../../../../utils/checkFilter';
import TreeSelect from '../../../../components_/Fields/AntTreeSelect';
import renameDepartmentsProperties from '../../../../utils/renameDepartmentsProperties';
import AntSelect from '../../../../components_/Fields/AntSelect';

interface IProps {
  data: IListProps['data'];
  filter: any;
  list: IListProps['list']['data'];
  isLoadingExportList: boolean;
  onChange: any;
  onClickCreate: () => void;
  onClickExport: () => void;
  onReset: () => void;
}
const useStyles = makeStyles(({ spacing }) => ({
  addButton: {
    float: 'right',
    marginBottom: '0.25rem',
    marginLeft: '1rem'
  },
  exportButton: {
    float: 'right',
    marginBottom: '0.25rem'
  },
  resetButton: {
    marginBottom: '0.25rem'
  }
}));

const Filter: FC<IProps> = ({
  data,
  filter,
  list,
  isLoadingExportList,
  onChange,
  onClickCreate,
  onClickExport,
  onReset
}) => {
  const classes = useStyles();
  const { isShowReset, isDownload } = checkFilter(filter, [], list);
  const { fullTextSearch, departmentId, device, lastPosition } = filter;
  const { departments } = data;

  return (
    <ContentFilter>
      <FormRow alignItems="flex-end">
        <FormCol xs={2}>
          <Input
            name="fullTextSearch"
            type="search"
            size={'large'}
            allowClear={true}
            placeholder="Найти сотрудника"
            value={fullTextSearch}
            onChange={onChange}
          />
        </FormCol>
        <FormCol xs={2}>
          <TreeSelect
            name="departmentId"
            label="Подразделение"
            placeholder="Выберите подразделение"
            size={'large'}
            style={{ width: '100%', marginBottom: '0.25rem' }}
            treeDefaultExpandAll
            allowClear
            showSearch
            treeNodeFilterProp={'title'}
            treeData={renameDepartmentsProperties(departments)}
            value={departmentId}
            onChange={onChange}
          />
        </FormCol>
        <FormCol xs={2}>
          <AntSelect
            name="device"
            size="large"
            label="Наличие устройства"
            placeholder="Выберите значение"
            options={[
              { value: 'INCLUDED', label: 'Есть' },
              { value: 'NOT_INCLUDED', label: 'Нет' },
              { value: '', label: 'Отобразить все' }
            ]}
            allowClear
            value={device === null || device === undefined ? '' : String(device)}
            onChange={onChange}
            style={{ width: '100%', marginBottom: '0.25rem' }}
          />
        </FormCol>
        <FormCol xs={2}>
          <AntSelect
            name="lastPosition"
            size="large"
            label="Наличие сигнала устройства"
            placeholder="Выберите значение"
            options={[
              { value: 'INCLUDED', label: 'Есть' },
              { value: 'NOT_INCLUDED', label: 'Нет' },
              { value: '', label: 'Отобразить все' }
            ]}
            allowClear
            value={lastPosition === null || lastPosition === undefined ? '' : String(lastPosition)}
            onChange={onChange}
            style={{ width: '100%', marginBottom: '0.25rem' }}
          />
        </FormCol>
        {/*<FormCol style={{minWidth: '229px'}} zeroMinWidth={false} xs={2}>*/}
        {/*  {isShowReset && (*/}
        {/*    <Button*/}
        {/*      className={classes.resetButton}*/}
        {/*      color={'ghost' as ButtonColors}*/}
        {/*      onClick={onReset}*/}
        {/*    >*/}
        {/*      Сбросить фильтры*/}
        {/*    </Button>*/}
        {/*  )}*/}
        {/*</FormCol>*/}
        {/*<FormCol style={{minWidth: '214px'}} zeroMinWidth={false} justify={"flex-end"} xs={4}>*/}
        {/*  <FormCol justify={"flex-end"} xs={3}>*/}
        {/*    <Button*/}
        {/*        className={classes.exportButton}*/}
        {/*        icon="list"*/}
        {/*        color={'secondary' as ButtonColors}*/}
        {/*        disabled={isDownload}*/}
        {/*        loading={isLoadingExportList}*/}
        {/*        onClick={onClickExport}*/}
        {/*    >*/}
        {/*      Экспорт в CSV*/}
        {/*    </Button>*/}
        {/*</FormCol>*/}
        {/*<FormCol style={{minWidth: '274px'}} zeroMinWidth={false} justify={"flex-end"} xs={2}>*/}
        <FormCol xs={4}>
          <Button
            className={classes.addButton}
            icon="plus"
            color={'primary' as ButtonColors}
            onClick={onClickCreate}
          >
            Добавить сотрудника
          </Button>
          <Button
            className={classes.exportButton}
            icon="list"
            color={'secondary' as ButtonColors}
            disabled={isDownload}
            loading={isLoadingExportList}
            onClick={onClickExport}
          >
            Экспорт в CSV
          </Button>
        </FormCol>
      </FormRow>
    </ContentFilter>
  );
};

export default Filter;
