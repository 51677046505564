import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

import { paths } from '../../routes/Root/paths';

import Content from '../../components_/Content';

import Tabs from './Tabs';

import InfrastructureList from './Infrastructure/List';
import ProtectionsList from './Protections/List';
import ProtectionsForm from './Protections/Form';
import DevicesList from './Devices/List';
import DevicesForm from './Devices/Form';
import CameraList from './Cameras/List';

const Equipment: FC = () => {
  return (
    <Switch>
      {/* Формы */}
      <Route
        path={[
          paths.equipment.protections.create,
          paths.equipment.protections.edit(),
          paths.equipment.protections.copy()
        ]}
        component={ProtectionsForm}
      />
      <Route
        path={[
          paths.equipment.devices.create,
          paths.equipment.devices.edit(),
          paths.equipment.devices.copy()
        ]}
        component={DevicesForm}
      />

      {/* Списки */}
      <Route path={paths.equipment.root}>
        <Content title="Оборудование">
          <Tabs />
          <Switch>
            <Route exact path={paths.equipment.beacons.root} component={InfrastructureList} />
            <Route exact path={paths.equipment.protections.root} component={ProtectionsList} />
            <Route exact path={paths.equipment.root} component={DevicesList} />
            <Route exact path={paths.equipment.cameras.root} component={CameraList} />
          </Switch>
        </Content>
      </Route>
    </Switch>
  );
};

export default Equipment;
