/**
 * Модель для работы с зонами
 */

import { createModel, ModelConfig, RematchDispatch } from '@rematch/core';

import { Dispatch } from '../store';
import { IId, ISelect, IArea, ICoordXY, ICoordXYZW, INotification, IUser } from './types';
import MapsService, { IUpdateAreaBody } from '../services/MapsService';
import ProtectionsService from '../services/ProtectionsService';
import UsersService, { IGetAllParams as IGetUserAllParams } from '../services/UsersService';
import NotificationsService from '../services/NotificationsService';

export interface IData {
  dangers: ISelect[];
  protections: ISelect[];
  users: IUser[] | null;
  areas: IArea[];
}

interface IState {
  data: IData;
  item: IArea | null;
  hover: IArea | null;
  notifications: INotification[];
  coordinates: ICoordXYZW | null;
}

const initialState: IState = {
  data: {
    dangers: [],
    protections: [],
    users: null,
    areas: []
  },
  item: null,
  hover: null,
  notifications: [],
  coordinates: null
};

const model: ModelConfig<IState> = {
  state: initialState,
  reducers: {
    setData(state: IState, payload: IData): IState {
      return {
        ...state,
        data: {
          ...state.data,
          ...payload
        }
      };
    },
    setArea(state: IState, payload: IArea): IState {
      return { ...state, item: payload };
    },
    setHover(state: IState, payload: IArea): IState {
      return { ...state, hover: payload };
    },
    resetHover(state: IState): IState {
      const { hover } = initialState;

      return { ...state, hover };
    },
    setNotifications(state: IState, payload: INotification[]): IState {
      return { ...state, notifications: payload };
    },
    setCoordinates(state: IState, payload: ICoordXYZW): IState {
      return { ...state, coordinates: payload };
    },
    resetCoordinates(state: IState): IState {
      const { coordinates } = initialState;

      return { ...state, coordinates };
    }
  },
  effects: (dispatch: RematchDispatch) => {
    const d = dispatch as Dispatch;

    return {
      async getData() {
        const [
          { data: dangers },
          {
            data: { data: protections }
          },
          { data: areas }
        ] = await Promise.all([
          MapsService.getDangers(),
          ProtectionsService.getTypeAll({ pageSize: 100 }),
          MapsService.getAreasByQuery()
        ]);

        this.setData({ dangers, protections, areas });
      },
      async getDataUsers(params: IGetUserAllParams) {
        const {
          data: { employees: users }
          // ХАК - убрать когда появится универсальный метод получения максимального кол-ва записей
        } = await UsersService.getAllEmployees({ ...params, pageSize: 1000 });

        this.setData({ users });
      },
      async resetDataUsers() {
        const {
          data: { users }
        } = initialState;

        this.setData({ users });
      },
      async getArea(id: IId) {
        const { data } = await MapsService.getArea(id);

        this.setArea(data);
      },
      async resetArea() {
        const { item } = initialState;

        this.setArea(item);
      },
      async updateOrCreateArea(body: IUpdateAreaBody) {
        try {
          await MapsService.updateOrCreateArea(body);

          d.infrastructureMap.getMap();
        } catch (error) {
          return error;
        }
      },
      async deleteArea(id: IId) {
        try {
          await MapsService.deleteArea(id);

          d.infrastructureMap.getMap();
        } catch (error) {
          return error;
        }
      },
      focusArea(center: ICoordXY) {
        // const payload = { zoom: 20, center };
        const payload = { center };

        d.infrastructureMap.setFocus(payload);
      },
      async getNotifications(areaId: IId) {
        const {
          data: { data }
        } = await NotificationsService.getFullAll({ areaId });

        this.setNotifications(data);
      }
    };
  }
};

export const infrastructureAreas: typeof model = createModel(model);
