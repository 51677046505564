import React, {FC, useState} from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsButton } from '@mts-ds/components-react';

import { Dispatch, iRootState } from '../../../store';
import { ButtonColors, ButtonFormats } from '@mts-ds/components/dist/types/components/button/enums';
import Icon from '../../../components_/Icon';
import Tooltip from '../../../components_/Tooltip';
import {EditPlanMode} from '../../../models/types';
import ImageOverlay from "./ImageOverlay";
import {useHistory, useRouteMatch} from "react-router-dom";
import {guids, paths} from "../../Root/paths";
import isSatisfied from "../../../utils/isSatisfied";

const useStyles = makeStyles(({ spacing }) => ({
  edit: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 1000
  },
  rotate: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 1000
  },
  translate: {
    position: 'absolute',
    top: 60,
    right: 10,
    zIndex: 1000
  },
  scale: {
    position: 'absolute',
    top: 110,
    right: 10,
    zIndex: 1000
  },
  lock: {
    position: 'absolute',
    top: 160,
    right: 10,
    zIndex: 1000
  }
}));

const mapState = (state: iRootState) => ({
  mapId: state.infrastructureMap.map.id,
  editPlan: state.infrastructureMap.editPlan,
  editPlanMode: state.infrastructureMap.editPlanMode,
  isGeo: state.infrastructureMap.layers.isGeo,
  rights: state.securable.data

});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    setEditPlan: d.infrastructureMap.setEditPlan,
    setEditPlanMode: d.infrastructureMap.setEditPlanMode
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = connectedProps;

const EditPlan: FC<IProps> = ({ setEditPlan, editPlan, setEditPlanMode, editPlanMode, mapId, isGeo, rights }) => {
  const classes = useStyles();
  const history = useHistory();
  // tslint:disable-next-line:prettier
  const isPlaneCreate = useRouteMatch(paths.map.office.plan.create)?.isExact;
  const isPlaneUpdate = useRouteMatch(paths.map.office.plan.edit())?.isExact;

  const handleClick = () => () => {
    history.push(paths.map.office.plan.edit(mapId));
  };

  const handleEdit = (mode: EditPlanMode) => () => {
    setEditPlanMode(mode);
  };

  const checkAccess = isSatisfied(
      { ...{ uid: guids.map.office.plan.edit() }, ...{ action: 'Visibility' } },
      rights
  );

  return (
    <>
      <ImageOverlay mode={editPlanMode} /> {/* План */}
      {checkAccess && (
      !editPlan ? (
          <>
      <Grid className={classes.edit}>
        <Tooltip size="l" label="Редактировать план">
          <MtsButton
            disabled={!isGeo || !mapId}
            format={'icon' as ButtonFormats}
            color={'outline' as ButtonColors}
            key={'isGeo'}
            onClick={handleClick()}
          >
            <Grid container justify="center" alignItems="center" wrap="nowrap">
              <Icon name="edit" size="m" />
            </Grid>
          </MtsButton>
        </Tooltip>
      </Grid>
            </>
      ):(
          <>
      <Grid className={classes.rotate}>
        <Tooltip size="l" label="Повернуть план" placement="left">
          <MtsButton
              active={editPlanMode === EditPlanMode.Rotate}
            format={'icon' as ButtonFormats}
            color={'outline' as ButtonColors}
            key={'rotate'}
            onClick={handleEdit(EditPlanMode.Rotate)}
          >
            <Grid container justify="center" alignItems="center" wrap="nowrap">
              <Icon name="replay" size="m" />
            </Grid>
          </MtsButton>
        </Tooltip>
      </Grid>
      <Grid className={classes.translate}>
        <Tooltip size="l" label="Переместить план" placement="left">
          <MtsButton
            active={editPlanMode === EditPlanMode.Translate}
            format={'icon' as ButtonFormats}
            color={'outline' as ButtonColors}
            key={'translate'}
            onClick={handleEdit(EditPlanMode.Translate)}
          >
            <Grid container justify="center" alignItems="center" wrap="nowrap">
              <Icon name="translate" size="m" />
            </Grid>
          </MtsButton>
        </Tooltip>
      </Grid>
      <Grid className={classes.scale}>
        <Tooltip size="l" label="Масштабировать план" placement="left">
          <MtsButton
              active={editPlanMode === EditPlanMode.Scale}
            format={'icon' as ButtonFormats}
            color={'outline' as ButtonColors}
            key={'scale'}
            onClick={handleEdit(EditPlanMode.Scale)}
          >
            <Grid container justify="center" alignItems="center" wrap="nowrap">
              <Icon name="scale" size="m" />
            </Grid>
          </MtsButton>
        </Tooltip>
      </Grid>
      <Grid className={classes.lock}>
        <Tooltip size="l" label="Зафиксировать план" placement="left">
          <MtsButton
              active={editPlanMode === EditPlanMode.Lock}
            format={'icon' as ButtonFormats}
            color={'outline' as ButtonColors}
            key={'lock'}
            onClick={handleEdit(EditPlanMode.Lock)}
          >
            <Grid container justify="center" alignItems="center" wrap="nowrap">
              <Icon name="lock" size="m" />
            </Grid>
          </MtsButton>
        </Tooltip>
      </Grid> </>)
      )}
    </>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(EditPlan);
