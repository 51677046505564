import React, { FC, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';

import { Dispatch, iRootState, store } from '../../../store';

import UserMarkerGroup from './UserMarkerGroup';
import UserMarkerListItem from './UserMarkerListItem';

import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { IId } from '../../../models/types';

import apiConstants from '../../../constants/apiConstants';
import LocalStorageService from '../../../services/LocalStorageService';

const mapState = (state: iRootState) => ({
  map: state.infrastructureMap.map,
  users: state.infrastructureUsers.users,
  activeUser: state.infrastructureUsers.item,
  layers: state.infrastructureMap.layers
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getUsers: d.infrastructureUsers.getUsers,
    resetUsers: d.infrastructureUsers.resetUsers,
    setUsersListInMap: d.infrastructureUsers.setUsersListInMap
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = connectedProps;

const UserMarkerList: FC<IProps> = ({
  users,
  map,
  layers,
  getUsers,
  setUsersListInMap,
  activeUser,
  resetUsers
}) => {
  const { id } = map;
  const { isUsers } = layers;
  const { REACT_APP_DEV_URL } = process.env;
  const [connectionRef, setConnection] = useState<HubConnection>();

  const { Connected, Disconnected } = HubConnectionState;

  const url = `${REACT_APP_DEV_URL || ''}${apiConstants.prefix}`;
  const { accessToken } = LocalStorageService.getToken();

  const createHubConnection = () => {
    const connection = new HubConnectionBuilder()
      .configureLogging(1)
      .withUrl(`${url}hubs/moves`, {
        accessTokenFactory: () => {
          return `${accessToken}`;
        }
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: () => {
          return 5000;
        }
      })
      .build();

    setConnection(connection);
  };

  const signalRStop = () => {
    if (connectionRef) {
      connectionRef.stop();
    }
  };

  const signalRSubscribe = async (mapId: IId) => {
    // store.dispatch.infrastructureUsers.getUsers({payload:{
    //     "userId": "e134e97e-6bdb-4328-8b2b-1779c1ad6a67",
    //     "username": "asperevoz",
    //     "position": {
    //         "type": "Real",
    //         "officeId": null,
    //         "mapId": "14a3aae6-eae8-4868-8445-ba5dc0b33c40",
    //         "areaId": null,
    //         "x": 11.93698433652121,
    //         "y": 33.157681274359575,
    //         "z": null,
    //         "isDefinedLocal": true,
    //         "lat": 55.69173382272405,
    //         "lon": 37.66030041099684,
    //         "alt": null,
    //         "isDefinedGlobal": true,
    //         "kinematic": 0,
    //         "tolerance": 0,
    //         "timeStart": "2025-03-30T23:45:33.9998946+00:00",
    //         "timeEnd": "2025-03-30T23:45:33.9998946+00:00",
    //         "receive-backend": "2025-03-30T23:45:33.9998946+00:00"
    //     },
    //     "trackInfo": {
    //         "online": true,
    //         "mapId": "14a3aae6-eae8-4868-8445-ba5dc0b33c40",
    //         "areaId": null,
    //         "states": [],
    //         "measurements": [
    //             {
    //                 "time": "2025-03-28T09:51:37.7497886+00:00",
    //                 "type": "PULSE",
    //                 "value": "n/a"
    //             },
    //           {
    //             "time": "2025-03-28T09:51:37.7497886+00:00",
    //             "type": "DEVICE_CHARGE",
    //             "value": null
    //           }
    //         ],
    //         "protAssigned": [],
    //         "protChecked": [],
    //         "mapColor": "#00c20d",
    //         "tag": {
    //             "id": 1409,
    //             "uid": "50b255e7-cc3b-4dae-808d-af9c84fa4e1e",
    //             "tagType": "MAC",
    //             "number": "C3-00-00-04-04-28",
    //             "from": "2025-02-10T16:56:44.534049+00:00",
    //             "descr": ""
    //         },
    //         "lastPosition": {
    //             "type": "Real",
    //             "officeId": null,
    //             "mapId": "14a3aae6-eae8-4868-8445-ba5dc0b33c40",
    //             "areaId": null,
    //             "x": 11.93698433652121,
    //             "y": 33.157681274359575,
    //             "z": null,
    //             "isDefinedLocal": true,
    //             "lat": 55.69173382272405,
    //             "lon": 37.66030041099684,
    //             "alt": null,
    //             "isDefinedGlobal": true,
    //             "kinematic": 0,
    //             "tolerance": 0,
    //             "timeStart": "2025-03-30T23:45:33.9998946+00:00",
    //             "timeEnd": "2025-03-30T23:45:33.9998946+00:00",
    //             "receive-backend": "2025-03-30T23:45:33.9998946+00:00"
    //         },
    //         "lastContact": "2025-03-30T23:45:33.9998946+00:00"
    //     },
    //     "charge": {
    //         "time": "2025-03-30T23:45:34.059086+00:00",
    //         "volt": null,
    //         "precent": null,
    //         "isCharging": null
    //     }
    // }, mapId});
    if (connectionRef) {
      if (connectionRef.state === Disconnected) {
        await connectionRef.start().then(() => {
          connectionRef.invoke('SubscribeToMap', mapId);
        });
        connectionRef.onreconnected(error => {
          connectionRef.invoke('SubscribeToMap', mapId);
        });
      } else {
        await connectionRef.invoke('SubscribeToMap', mapId);
      }
      connectionRef.on('UpdateInfo', payload => {
        // tslint:disable-next-line: no-console
        console.log(payload);
        getUsers({ payload, mapId });
      });
    }
  };

  const signalRUnsubscribe = (mapId: IId) => {
    if (connectionRef) {
      if (connectionRef.state === Connected) {
        connectionRef.off('UpdateInfo');
        // tslint:disable-next-line: no-console
        connectionRef.invoke('UnsubscribeFromMap', mapId).catch(err => console.error(err));
      }
    }
  };

  useEffect(() => {
    createHubConnection();
    return () => {
      signalRStop();
    };
  }, []);

  useEffect(() => {
    if (id) {
      // @ts-ignore
      setUsersListInMap(id).then(r => {
        // setUsersListInMap(id)
        signalRSubscribe(id);
      });
      return () => {
        signalRUnsubscribe(id);
      };
    }
  }, [id]);
  return (
    <>
      <UserMarkerGroup>
        {isUsers &&
          users.map(user =>
            activeUser && activeUser.id === user.id ? (
              <></>
            ) : (
              <UserMarkerListItem key={user.id} user={user} />
            )
          )}
      </UserMarkerGroup>
      {activeUser && <UserMarkerListItem key={activeUser.id} user={activeUser} />}
    </>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(UserMarkerList);
