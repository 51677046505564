import React, { FC } from 'react';
import moment from 'moment';

import { IReportStayInAreas } from '../../../models/types';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

import { Table } from 'antd';

interface IProps {
  list: IReportStayInAreas[];
}

const List: FC<IProps> = ({ list }) => {
  const columns = [
    // {
    //   title: '№',
    //   dataIndex: 'npp',
    //   key: 'npp'
    // },
    {
      title: 'Сотрудник',
      dataIndex: 'employeeName',
      key: 'employeeName'
    },
    {
      title: 'Зона',
      dataIndex: 'areaName',
      key: 'areaName'
    },
    {
      title: 'План',
      dataIndex: 'mapName',
      key: 'mapName'
    },
    {
      title: 'Место',
      dataIndex: 'officeName',
      key: 'officeName'
    },
    {
      title: 'Дата/Время входа',
      dataIndex: 'enterTime',
      key: 'enterTime',
      render: (_: any, record: IReportStayInAreas) => (
        <div>{record.enterTime ? moment(record.enterTime).format('DD.MM.YYYY HH:mm:ss') : '-'}</div>
      )
    },
    {
      title: 'Дата/Время выхода',
      dataIndex: 'leaveTime',
      key: 'leaveTime',
      render: (_: any, record: IReportStayInAreas) => (
        <div>{record.leaveTime ? moment(record.leaveTime).format('DD.MM.YYYY HH:mm:ss') : '-'}</div>
      )
    },
    {
      title: 'Время пребывания в зоне',
      dataIndex: 'duration',
      key: 'duration'
    }
  ];

  return (
    <Table
      style={{ marginTop: '1em', overflow: 'auto' }}
      size="small"
      rowKey="npp"
      pagination={false}
      columns={columns}
      dataSource={list}
      tableLayout={'auto'}
      expandable={{
        expandIconColumnIndex: 1,
        indentSize: 20,
        expandIcon: ({ expanded, expandable, onExpand, record }) =>
          expandable ? (
            expanded ? (
              <UpOutlined
                onClick={e => onExpand(record, e)}
                aria-label="Развернуть строку"
                style={{ marginRight: '8px', marginTop: '1px' }}
              />
            ) : (
              <DownOutlined
                onClick={e => onExpand(record, e)}
                aria-label="Свернуть строку"
                style={{ marginRight: '8px', marginTop: '1px' }}
              />
            )
          ) : (
            <DownOutlined style={{ marginRight: '8px', marginTop: '1px', visibility: 'hidden' }} />
          )
      }}
    />
  );
};

export default List;
