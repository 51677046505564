import React, { FC, useEffect, useState } from 'react';
import { MtsButton, MtsRadioGroup } from '@mts-ds/components-react';
import { ButtonSizes, ButtonColors } from '@mts-ds/components/dist/types/components/button/enums';
import { RadioSizes } from '@mts-ds/components/dist/types/components/radio/enums/sizes.enum';
import { DropdownSizes } from '@mts-ds/components/dist/types/components/dropdown/enums/sizes.enum';
import { InputSizes } from '@mts-ds/components/dist/types/components/input/enums/sizes.enum';
import { makeStyles } from '@material-ui/core';
import moment, { Moment } from 'moment';

import Input from '../../../components_/Fields/Input';
import FormGroup from '../../../components_/Fields/components/FormGroup';

import checkFilter from '../../../utils/checkFilter';
import { IProps as IStayInAreasProps } from '../StayInAreas';

import ContentFilter from '../../../components_/Content/ContentFilter';
import FormRow from '../../../components_/Form/FormRow';
import FormCol from '../../../components_/Form/FormCol';
import Dropdown from '../../../components_/Fields/Dropdown';
import Tooltip from '../../../components_/Tooltip';
import Button from '../../../components_/Fields/Button';
import AntSelect from '../../../components_/Fields/AntSelect';
import AntDatePicker from '../../../components_/Fields/AntDatePicker';

import { CollapseTransitions } from './types';
import RadioButton from './RadioButton';
import { IArea, IId } from '../../../models/types';
import Checkbox from '../../../components_/Fields/Checkbox';

const useStyles = makeStyles(({ spacing }) => ({
  group: {
    display: 'flex',
    alignItems: 'flex-end',
    '& > *': {
      marginBottom: 0
    },
    '& > *:not(:last-child)': {
      marginRight: spacing(2)
    }
  }
}));

interface IProps {
  data: IStayInAreasProps['data'];
  filter: any;
  isLoadingGetList: boolean;
  isLoadingExportList: boolean;
  list: IStayInAreasProps['list']['data'];
  onChange: any;
  onClickApply: () => void;
  onClickExport: () => void;
  onClickReset: () => void;
  getDataAreas: IStayInAreasProps['getDataAreas'];
  resetDataAreas: IStayInAreasProps['resetDataAreas'];
}

const defaultCollapseTransitions = CollapseTransitions.Detailed;

const Filter: FC<IProps> = ({
  data,
  filter,
  isLoadingGetList,
  isLoadingExportList,
  list,
  onChange,
  onClickApply,
  onClickExport,
  onClickReset,
  getDataAreas,
  resetDataAreas
}) => {
  const [collapseTransitions, setCollapseTransitions] = useState(defaultCollapseTransitions);

  const { offices, areas, employees } = data;
  const {
    mapId,
    listOfAreas,
    listOfEmployees,
    collapseLeavesPeriodSeconds,
    from,
    to,
    inAreaNow,
    isIncludeTimeout
  } = filter;

  const { isApply, isDownload } = checkFilter(filter, ['from', 'to'], list);

  const classes = useStyles();

  useEffect(() => {
    if (mapId) {
      getDataAreas(mapId);

      return () => {
        resetDataAreas();
        onChange({
          target: {
            name: 'listOfAreas',
            value: []
          }
        });
      };
    }
  }, [mapId]);

  const officeOptions = offices.map(({ name, plans }) => ({ [name]: plans }));

  // Create options directly from the employees array
  const employeeOptions = employees.map((employee: { id: IId; fullName: string }) => ({
    label: employee.fullName,
    value: employee.id
  }));

  const areasOptions = areas.map(({ id, name }: IArea) => ({
    label: name,
    value: id
  }));

  const handleClickReset = () => {
    setCollapseTransitions(defaultCollapseTransitions);
    onClickReset();
  };

  const handleChangeDate = (fieldName: string) => (date: unknown) => {
    onChange({
      target: {
        name: fieldName,
        value: date && (date as Moment).format()
      }
    });
  };

  const handleChangeRadio = (event: any) => {
    const currValue = typeof event === 'string' ? event : event.target.value;

    let currCollapseLeavesPeriodSeconds;
    let currIsCollapseTransitions = false;

    setCollapseTransitions(currValue);

    switch (currValue) {
      case CollapseTransitions.Detailed:
        currCollapseLeavesPeriodSeconds = 0;
        break;

      case CollapseTransitions.Collapsed:
        currCollapseLeavesPeriodSeconds = 60;
        break;

      case CollapseTransitions.Consolidated:
        currIsCollapseTransitions = true;
        break;

      default:
        break;
    }

    onChange({
      target: {
        name: 'collapseLeavesPeriodSeconds',
        value: currCollapseLeavesPeriodSeconds
      }
    });

    onChange({
      target: {
        name: 'isCollapseTransitions',
        value: currIsCollapseTransitions
      }
    });
  };

  const handleRadioButtonClick = (value: CollapseTransitions) => {
    handleChangeRadio(value);
  };

  return (
    <ContentFilter>
      <FormRow alignItems="flex-end" spacing={2}>
        <FormCol xs={2}>
          <AntSelect
            name="listOfEmployees"
            label="Сотрудник (необязательно)"
            placeholder="Выберите значение"
            options={employeeOptions}
            optionFilterProp="label"
            mode="multiple"
            allowClear
            value={listOfEmployees}
            onChange={onChange}
          />
        </FormCol>

        <FormCol xs={2}>
          <AntDatePicker
            name="from"
            value={from}
            label="Дата/время от"
            needConfirm={false}
            maxDate={moment()}
            onChange={handleChangeDate('from')}
            showTime
          />
        </FormCol>

        <FormCol xs={2}>
          <AntDatePicker
            name="to"
            value={to}
            label="Дата/время до"
            needConfirm={false}
            maxDate={moment()}
            onChange={handleChangeDate('to')}
            showTime
          />
        </FormCol>

        <FormCol xs="auto">
          <FormRow alignItems="flex-end" spacing={1}>
            <FormCol xs="auto">
              <MtsButton
                disabled={isApply}
                size={'s' as ButtonSizes}
                loading={isLoadingGetList}
                onClick={onClickApply}
              >
                Применить
              </MtsButton>
            </FormCol>

            <FormCol xs="auto">
              <MtsButton
                size={'s' as ButtonSizes}
                color={'secondary' as ButtonColors}
                onClick={handleClickReset}
              >
                Сбросить
              </MtsButton>
            </FormCol>

            <FormCol>
              <Tooltip label="Скачать отчёт">
                <Button
                  icon="download"
                  disabled={isDownload}
                  size={'s' as ButtonSizes}
                  loading={isLoadingExportList}
                  color={'secondary' as ButtonColors}
                  onClick={onClickExport}
                >
                  Скачать
                </Button>
              </Tooltip>
            </FormCol>
          </FormRow>
        </FormCol>
      </FormRow>

      <FormRow alignItems="flex-end" spacing={2}>
        <FormCol xs={2}>
          <Dropdown
            name="mapId"
            label="Место/План (необязательно)"
            placeholder="Выберите значение"
            groupOptions={officeOptions}
            value={mapId}
            size={'s' as DropdownSizes}
            onMtsChange={onChange}
          />
        </FormCol>

        <FormCol xs={2}>
          <AntSelect
            name="listOfAreas"
            label="Зоны (необязательно)"
            placeholder="Выберите значение"
            options={areasOptions}
            value={listOfAreas}
            disabled={!mapId}
            mode="multiple"
            allowClear
            optionFilterProp="label"
            onChange={onChange}
            style={{ width: '100%', marginBottom: '0.25rem' }}
          />
        </FormCol>

        <FormCol xs={2}>
          <AntSelect
            name="inAreaNow"
            size="middle"
            label="Нахождение в зоне"
            placeholder="Выберите значение"
            options={[
              { value: 'true', label: 'Сейчас в зоне' },
              { value: 'false', label: 'Были в зоне' },
              { value: '', label: 'Любое' }
            ]}
            allowClear
            value={inAreaNow === null || inAreaNow === undefined ? '' : String(inAreaNow)}
            onChange={onChange}
            style={{ width: '100%', marginBottom: '0.25rem' }}
          />
        </FormCol>

        <FormCol>
          <FormGroup>
            <MtsRadioGroup
              size={'s' as RadioSizes}
              className={classes.group}
              value={collapseTransitions}
              onMtsChange={handleChangeRadio}
            >
              <RadioButton
                radioValue={CollapseTransitions.Detailed}
                onClick={handleRadioButtonClick}
              />

              <RadioButton
                radioValue={CollapseTransitions.Collapsed}
                onClick={handleRadioButtonClick}
              />

              {collapseTransitions === CollapseTransitions.Collapsed && (
                <Input
                  type="number"
                  label="Период, сек"
                  size={'s' as InputSizes}
                  name="collapseLeavesPeriodSeconds"
                  value={collapseLeavesPeriodSeconds}
                  onMtsChange={e => {
                    e.stopPropagation();
                    onChange(e);
                  }}
                  isHideDescription
                />
              )}

              <RadioButton
                radioValue={CollapseTransitions.Consolidated}
                onClick={handleRadioButtonClick}
              />
            </MtsRadioGroup>
          </FormGroup>
        </FormCol>
      </FormRow>
      <FormRow alignItems="flex-end" spacing={2}>
        <FormCol xs={3}>
          <FormGroup>
            <Checkbox
              style={{ marginBottom: '10px' }}
              disabled={
                collapseTransitions !== CollapseTransitions.Consolidated ||
                inAreaNow === null ||
                inAreaNow === undefined ||
                inAreaNow === ''
              }
              checked={
                collapseTransitions !== CollapseTransitions.Consolidated ||
                inAreaNow === null ||
                inAreaNow === undefined ||
                inAreaNow === ''
                  ? false
                  : isIncludeTimeout
              }
              name="isIncludeTimeout"
              label="Учитывать сотрудников без актуальной геопозиции"
              onMtsChange={onChange}
            />
            {/*<FormCheckbox*/}
            {/*    name="IsIncludeTimeout"*/}
            {/*    label="Не учитвыать сотрудников без сигналов"*/}
            {/*/>*/}
          </FormGroup>
        </FormCol>
      </FormRow>
    </ContentFilter>
  );
};

export default Filter;
