import React, { FC } from 'react';
import { ButtonColors } from '@mts-ds/components/dist/types/components/button/enums';

import { IProps as IListProps } from './';

import ContentFilter from '../../../../components_/Content/ContentFilter';
import FormRow from '../../../../components_/Form/FormRow';
import FormCol from '../../../../components_/Form/FormCol';
import Dropdown from '../../../../components_/Fields/Dropdown';
import Button from '../../../../components_/Fields/Button';
import checkFilter from '../../../../utils/checkFilter';

interface IProps {
  data: IListProps['data'];
  filter: any;
  onChange: any;
  onClickCreate: () => void;
  onReset: () => void;
}

const Filter: FC<IProps> = ({ data, filter, onChange, onClickCreate, onReset }) => {
  const { shortUsers, notificationTypes, notificationTargets } = data;
  const { notificationType, notificationTarget, employeesList } = filter;
  const { isShowReset } = checkFilter(filter);

  const typeOptions = notificationTypes.map(({ class: id, descr }) => ({
    id,
    name: descr
  }));

  const targetOptions = notificationTargets.map(({ target, descr }) => ({
    id: target,
    name: descr
  }));

  const userOptions = shortUsers.map(({ id, fullName }) => ({
    id,
    name: fullName
  }));

  return (
    <ContentFilter>
      <FormRow alignItems="flex-end">
        <FormCol>
          <Dropdown
            name="notificationType"
            label="Тип события"
            placeholder="Выберите тип"
            options={typeOptions}
            value={notificationType}
            onMtsChange={onChange}
          />
        </FormCol>
        <FormCol>
          <Dropdown
            name="notificationTarget"
            label="Получатели уведомления"
            placeholder="Выберите получателя"
            options={targetOptions}
            value={notificationTarget}
            onMtsChange={onChange}
          />
        </FormCol>
        <FormCol>
          <Dropdown
            name="employeesList"
            label="Сотрудник"
            placeholder="Выберите сотрудника"
            options={userOptions}
            value={employeesList}
            onMtsChange={onChange}
          />
        </FormCol>
        <FormCol zeroMinWidth={false} container justify="flex-end">
          <Button icon="plus" color={'secondary' as ButtonColors} onClick={onClickCreate}>
            Добавить шаблон уведомления
          </Button>
        </FormCol>
        {isShowReset && (
          <FormCol xs={12}>
            <Button color={'ghost' as ButtonColors} onClick={onReset}>
              Сбросить фильтры
            </Button>
          </FormCol>
        )}
      </FormRow>
    </ContentFilter>
  );
};

export default Filter;
