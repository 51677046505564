import React, { FC } from 'react';
import { MtsRadio } from '@mts-ds/components-react';
import { ButtonSizes, ButtonColors } from '@mts-ds/components/dist/types/components/button/enums';
import { Grid, makeStyles } from '@material-ui/core';

import { IIcon } from '../../../assets_/icons';
import Typography from '../../../components_/Typography';

import Icon from '../../../components_/Icon';
import Tooltip from '../../../components_/Tooltip';
import Button from '../../../components_/Fields/Button';

import { CollapseTransitions } from './types';

interface IProps {
  radioValue: CollapseTransitions;
  onClick: (value: CollapseTransitions) => void;
}

const tooltips: Record<CollapseTransitions, string> = {
  [CollapseTransitions.Detailed]: 'Отображает все записи о вхождениях в зоны без группировки',
  [CollapseTransitions.Collapsed]:
    'Объединяет идущие подряд записи с повторяющимися значениями зон в одну за указанный интервал в секундах',
  [CollapseTransitions.Consolidated]:
    'Объединяет записи о вхождениях за период по каждой зоне и по сотруднику'
};

const labels: Record<CollapseTransitions, string> = {
  [CollapseTransitions.Detailed]: 'Детальный отчет',
  [CollapseTransitions.Collapsed]: 'Свернутый отчет',
  [CollapseTransitions.Consolidated]: 'Сводный отчет'
};

const icons: Record<CollapseTransitions, IIcon> = {
  [CollapseTransitions.Detailed]: 'catalog',
  [CollapseTransitions.Collapsed]: 'applications',
  [CollapseTransitions.Consolidated]: 'personOutline'
};

const useStyles = makeStyles(({ spacing }) => ({
  button: {
    '& > *:not(:last-child)': {
      marginRight: spacing(0.5)
    }
  },
  icon: {
    fontSize: 16
  },
  disabledRadio: {
    pointerEvents: 'none'
  }
}));

export const RadioButton: FC<IProps> = ({ radioValue, onClick }) => {
  const classes = useStyles();

  const handleClick = () => {
    onClick(radioValue);
  };

  return (
    <Tooltip label={tooltips[radioValue]}>
      <Button size={'s' as ButtonSizes} color={'outline' as ButtonColors} onClick={handleClick}>
        <Grid container alignItems="center" wrap="nowrap" className={classes.button}>
          <MtsRadio value={radioValue} className={classes.disabledRadio} />
          <Typography variant="p2Regular">{labels[radioValue]}</Typography>
          <Icon name={icons[radioValue]} className={classes.icon} />
        </Grid>
      </Button>
    </Tooltip>
  );
};

export default RadioButton;
