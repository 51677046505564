import React, { ComponentProps, FC } from 'react';
import { DatePicker as AntDatePicker } from 'antd';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';

import FormGroup, { IPropsText as IFormGroupTextProps } from '../components/FormGroup';
import { Moment } from 'moment';
import moment from 'moment/moment';

const MomentDatePicker = AntDatePicker.generatePicker<Moment>(momentGenerateConfig);
interface IDatePickerProps {
  name: string;
  valid?: boolean;
  value?: any;
}

export type IProps = IFormGroupTextProps &
  IDatePickerProps &
  ComponentProps<typeof MomentDatePicker>;

const DatePicker: FC<IProps> = ({ label, tooltip, description, name, valid, value, ...props }) => {
  return (
    <FormGroup label={label} description={description} tooltip={tooltip} valid={valid}>
      <MomentDatePicker
        style={{ width: '100%' }}
        value={value ? moment(value) : ''}
        format={'DD.MM.YYYY HH:mm:ss'}
        {...props}
      />
    </FormGroup>
  );
};

export default DatePicker;
