import React, { FC, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as ArrowDown } from '../../../../assets_/icons/arrowDown.svg';
import { ReactComponent as ArrowUp } from '../../../../assets_/icons/arrowUp.svg';
import TreeView from '@material-ui/lab/TreeView';
import theme from '../../../../theme';
import { useHistory } from 'react-router-dom';
import TreeNodeComponent from './TreeItem';
import Typography from '../../../../components_/Typography';
import { Button } from 'antd';
import Icon from '../../../../components_/Icon';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    marginTop: spacing(5),
    paddingBottom: spacing(5)
    // height: '90vh',
    // flexGrow: 1,
    // width: 400,
    // overflow: 'auto'
  },
  typography: {
    fontSize: '17px',
    lineHeight: '24px',
    fontWeight: 500,
    padding: 5
  },
  iconContainer: {
    width: '24px'
  },
  gridRoot: {
    marginTop: spacing(4)
  }
}));

interface IProps {
  secTreeHierarchy: any;
  defaultSelected?: any;
  onClickUpdateOnCrate: any;
  onClickDelete: any;
}
export interface ITreeNode {
  id: string | null;
  name: string;
  parentId: string | null;
  children: ITreeNode[];
}

const FormTree: FC<IProps> = ({
  secTreeHierarchy,
  defaultSelected,
  onClickDelete,
  onClickUpdateOnCrate
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [isAddParent, setIsAddParent] = useState(false);

  const handleSelect = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    history.push('?root=' + nodeIds);
  };

  const getIds = (array: any[]) => {
    let ids: string[] = [];

    array.forEach(item => {
      ids.push(item.id); // Добавляем ID текущего элемента
      if (item.children) {
        ids = ids.concat(getIds(item.children)); // Рекурсивно добавляем ID дочерних элементов
      }
    });

    return ids;
  };

  const renderTree = (nodes: ITreeNode[]) => {
    return nodes.map(node => (
      <TreeNodeComponent
        key={'parent_' + node.id}
        node={node}
        onClickDelete={onClickDelete}
        onClickUpdateOnCrate={onClickUpdateOnCrate}
      />
    ));
  };

  return (
    <>
      <Typography variant="h1Bold" className={classes.root}>
        Подразделения{' '}
        {
          <Button
            type="default"
            onClick={() => setIsAddParent(true)}
            icon={<Icon name={'plus'} size="m" />}
            size={'large'}
          />
        }
      </Typography>
      <TreeView
        defaultCollapseIcon={
          <ArrowUp style={{ width: 24, height: 24 }} className={classes.iconContainer} />
        }
        defaultExpandIcon={
          <ArrowDown style={{ width: 24, height: 24 }} className={classes.iconContainer} />
        }
        onNodeSelect={handleSelect}
        defaultExpanded={getIds(secTreeHierarchy)}
        defaultSelected={[defaultSelected ? defaultSelected : null]}
      >
        <TreeNodeComponent
          key={'parent_' + 0}
          isAddParent={isAddParent}
          setIsAddParent={() => setIsAddParent(false)}
          node={{ id: null, parentId: null, name: '', children: [] }}
          onClickDelete={onClickDelete}
          onClickUpdateOnCrate={onClickUpdateOnCrate}
        />
        {renderTree(secTreeHierarchy)}
      </TreeView>
    </>
  );
};

export default FormTree;
