import React, { FC } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { SpinnerSizes } from '@mts-ds/components/dist/types/components/spinner/enums';

import { IUser } from '../../../models/types';

import Typography from '../../Typography';
import Spinner from '../../Spinner';

import FormUserListFilter from './FormUserListFilter';
import FormUserListItem from './FormUserListItem';

const useStyles = makeStyles(({ spacing }) => ({
  listWrapper: {
    height: 314,
    paddingRight: spacing(2),
    overflow: 'auto'
  },
  list: {
    '& > *:not(:last-child)': {
      marginBottom: spacing(1)
    }
  }
}));

interface IProps {
  title: string;
  users: {
    data: IUser[];
    pageIndex: number;
    total: number;
  };
  getUsers: any;
}

const FormUserList: FC<IProps> = ({ title, users, getUsers }) => {
  const { data, total, pageIndex } = users;

  const classes = useStyles();

  // tslint:disable-next-line: no-shadowed-variable
  const handleLoadMore = async (pageIndex: number) => {
    await getUsers({ pageIndex });
  };

  const hasMore = Boolean(total && data.length < total);
  return (
    <Grid>
      <Typography variant="h3Medium">{title}</Typography>
      <FormUserListFilter getUsers={getUsers} />
      <Grid className={classes.listWrapper}>
        <InfiniteScroll
          key={pageIndex ? 1 : 0}
          pageStart={pageIndex}
          initialLoad={false}
          hasMore={hasMore}
          loadMore={handleLoadMore}
          useWindow={false}
          loader={<Spinner key={pageIndex ? 1 : 0} size={'m' as SpinnerSizes} />}
          className={classes.list}
        >
          {data.map(user => (
            <FormUserListItem key={user.id} user={user} />
          ))}
        </InfiniteScroll>
      </Grid>
    </Grid>
  );
};

export default FormUserList;
