import React, { FC, useState } from 'react';
import { connect } from 'react-redux';

import { Dispatch, iRootState } from '../../../../../../store';
import getSearchedData from '../../../../../../utils/getSearchedData';

import DrawerContentList from '../../../../../../components_/Drawer/DrawerContentList';
import DrawerContentListSearch from '../../../../../../components_/Drawer/DrawerContentListSearch';
import EmptyState from '../../../../../../components_/EmptyState';
import { paths } from '../../../../../Root/paths';
import { Badge, Button, Collapse, Flex, Popover, Table } from 'antd';
import { useHistory } from 'react-router-dom';
import { IId, IUserMap, UserStatusPriority } from '../../../../../../models/types';
import moment from 'moment/moment';
import getFullName from '../../../../../../utils/getFullName';
import Icon from '../../../../../../components_/Icon';
import Link from 'antd/lib/typography/Link';
import { MtsDivider, useMtsModal } from '@mts-ds/components-react';
import Modal from '../../../../../../components_/Modal';
import { PhoneOutlined } from '@ant-design/icons';
import { useRequestHandler } from '../../../../../../hooks';
import { makeStyles } from '@material-ui/core/styles';

const mapState = (state: iRootState) => ({
  users: state.infrastructureUsers.users,
  isSendDeviceNotificationAvailable: state.admin.isSendDeviceNotificationAvailable
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    sendNotification: d.infrastructureSending.sendNotification
  };
};
interface IParams {
  areaId: IId;
}

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = connectedProps & IParams;

const useStyles = makeStyles(({ spacing }) => ({
  divider: {
    margin: spacing(2, 0)
  }
}));

const Users: FC<IProps> = ({
  users,
  sendNotification,
  isSendDeviceNotificationAvailable,
  areaId
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [search, setSearch] = useState<string>('');
  const [active, setActive] = useState<IUserMap | null>(null);

  const requestHandler = useRequestHandler();

  const handleChangeSearch = (event: any) => {
    setSearch(event.target.value);
  };

  const handleClickSend = (user: IUserMap) => () => {
    setActive(user);
    openSendModal();
  };

  const handleClick = (id: string | number | undefined) => {
    // focusOn();
    history.push(paths.map.office.users.show(id));
  };

  const userInArea =
    users && users.filter(element => element.trackInfo && element.trackInfo.areaId === areaId);
  const searchedUsers = getSearchedData(userInArea, ['lastname', 'name'], search);
  const searchedUsersLength = (searchedUsers || []).length;

  const groupByDepartment = (data: any[] | null) => {
    const grouped: Record<string, any[]> = {};

    // Проверяем, если data является массивом
    if (Array.isArray(data)) {
      data.forEach(item => {
        const departmentId = item.rootDepartmentId || 'no_department';

        // Инициализация массива для отдела, если еще не создан
        if (!grouped[departmentId]) {
          grouped[departmentId] = [];
        }

        grouped[departmentId].push(item);
      });
    }

    return grouped;
  };

  const groupedData = groupByDepartment(searchedUsers);

  const columns = [
    {
      hidden: true,
      title: 'Состояние (скрыт по умолчанию)',
      dataIndex: 'trackInfo.states',
      key: 'trackInfo.states',
      defaultSortOrder: 'descend' as const,
      sorter: {
        // @ts-ignore
        compare: (a, b) =>
          a.trackInfo.states &&
          Number(
            a.trackInfo.states.some(
              (child: { priority: UserStatusPriority }) =>
                child.priority === UserStatusPriority.FOCUSED || UserStatusPriority.CRITICAL
            )
          ) - b.trackInfo.states &&
          Number(
            b.trackInfo.states.some(
              (child: { priority: UserStatusPriority }) =>
                child.priority === UserStatusPriority.FOCUSED || UserStatusPriority.CRITICAL
            )
          ),
        multiple: 5
      },
      render: (_: any, record: IUserMap) => (
        <Link>
          {record.trackInfo &&
          record.trackInfo.states &&
          record.trackInfo.states.some(
            (child: { priority: UserStatusPriority }) =>
              child.priority === UserStatusPriority.FOCUSED || UserStatusPriority.CRITICAL
          )
            ? 'yes'
            : 'no'}
        </Link>
      )
    },
    {
      hidden: true,
      title: 'Активность (скрыт по умолчанию)',
      dataIndex: 'trackInfo.online',
      key: 'trackInfo.online',
      defaultSortOrder: 'descend' as const,
      sorter: {
        // @ts-ignore
        compare: (a, b) => Number(a.trackInfo.online) - Number(b.trackInfo.online),
        multiple: 3
      },
      render: (_: any, record: IUserMap) => (
        <Link>{record.trackInfo && record.trackInfo.online ? 'yes' : 'no'}</Link>
      )
    },
    {
      title: 'Персонал',
      dataIndex: 'lastname',
      key: 'lastname',
      // sorter: (a, b) => a.lastname.localeCompare(b.lastname),
      sorter: {
        // @ts-ignore
        compare: (a, b) => a.lastname.localeCompare(b.lastname),
        multiple: 1
      },
      render: (_: any, record: IUserMap) => <Link>{getFullName(record, true)}</Link>
    },
    {
      title: 'Время сигнала',
      key: 'timeEnd',
      // sorter: (a, b) => moment(a.position.timeEnd).unix() - moment(b.position.timeEnd).unix(),
      sorter: {
        // @ts-ignore
        compare: (a, b) => moment(a.position.timeEnd).unix() - moment(b.position.timeEnd).unix(),
        multiple: 2
      },
      filters: [
        {
          text: (
            <Flex gap={'small'} vertical={false} align="center" justify="space-between">
              <p>Активные</p>
              <Icon name="checkMark" size="s" />
            </Flex>
          ),
          value: true
        },
        {
          text: (
            <Flex gap={'small'} vertical={false} align="center" justify="space-between">
              <p>Неактивные</p>
              <Icon name="errorGray" size="s" />
            </Flex>
          ),
          value: false
        }
      ],
      // @ts-ignore
      onFilter: (value, record) =>
        record.trackInfo &&
        record.trackInfo.states &&
        record.trackInfo.states.some(
          (child: { priority: UserStatusPriority }) =>
            child.priority === UserStatusPriority.FOCUSED || UserStatusPriority.CRITICAL
        )
          ? true
          : record.trackInfo.online === value,
      render: (_: any, record: IUserMap) => (
        <Flex gap={1} vertical={false} align="center" justify="space-around">
          <div style={{ fontSize: '12px' }}>
            {moment(record.position.timeEnd).format('DD.MM.YYYY HH:mm:ss')}
          </div>
          {record.trackInfo &&
          record.trackInfo.states &&
          record.trackInfo.states.some(
            (child: { priority: UserStatusPriority }) =>
              child.priority === UserStatusPriority.CRITICAL ||
              child.priority === UserStatusPriority.FOCUSED
          ) ? (
            <Icon name="error" size="s" />
          ) : record.trackInfo && record.trackInfo.online ? (
            <Icon name="checkMark" size="s" />
          ) : (
            <Icon name="errorGray" size="s" />
          )}
        </Flex>
      )
    }
  ];

  function CustomRow(
    record: React.JSX.IntrinsicAttributes &
      React.ClassAttributes<HTMLTableRowElement> &
      React.HTMLAttributes<HTMLTableRowElement>
  ) {
    // @ts-ignore
    return record.children[0].props.record.trackInfo.online && isSendDeviceNotificationAvailable ? (
      <Popover
        placement="right"
        content={
          <Button
            size={'large'}
            // @ts-ignore
            onClick={handleClickSend(record.children[0].props.record)}
            icon={<PhoneOutlined />}
          />
        }
        trigger="hover"
      >
        <tr {...record} />
      </Popover>
    ) : (
      <tr {...record} />
    );
  }

  const [openSendModal] = useMtsModal(Modal, {
    header: 'Отправить сигнал',
    text: `Вы действительно хотите отправить сигнал на устройство -  ${active &&
      getFullName(active, true)}?`,
    buttonTextConfirm: 'Отправить',
    ...(active && {
      onMtsModalConfirm: async () => {
        const error = await sendNotification({ text: '!', employeesList: [active.id] });

        requestHandler({
          error,
          entity: 'send',
          isCreate: true
          // onSuccess: updateList
        });
      }
    })
  });

  return (
    <>
      {Boolean(userInArea.length) && (
        <>
          <DrawerContentList title="Сотрудники" count={searchedUsersLength}>
            <DrawerContentListSearch
              placeholder="Найти сотрудника"
              value={search}
              onMtsChange={handleChangeSearch}
            />
            {searchedUsers && (
              <>
                {(() => {
                  const items = Object.entries(groupedData).map(([departmentId, usersArr]) => {
                    const departmentName =
                      (usersArr[0] && usersArr[0].rootDepartmentName) || 'Без подразделения';
                    return {
                      key: departmentId,
                      label: (
                        <div>
                          {departmentName}{' '}
                          <Badge
                            style={{ color: 'black', fontSize: '12px', fontWeight: 500 }}
                            color={'#f2f3f7'}
                            count={usersArr.length}
                          />{' '}
                          {usersArr.some(
                            outer =>
                              outer.trackInfo &&
                              outer.trackInfo.states &&
                              outer.trackInfo.states.some(
                                (child: { priority: UserStatusPriority }) =>
                                  child.priority === UserStatusPriority.CRITICAL ||
                                  child.priority === UserStatusPriority.FOCUSED
                              )
                          ) && <Icon name="error" size="s" />}
                        </div>
                      ),
                      children: (
                        <Table
                          columns={columns}
                          dataSource={usersArr}
                          size="small"
                          rowKey="id"
                          pagination={false}
                          style={{ cursor: 'pointer' }}
                          components={{
                            body: {
                              row: CustomRow
                            }
                          }}
                          onRow={(record, rowIndex) => ({
                            onClick: event => handleClick(record.id) // click row
                          })}
                        />
                      )
                    };
                  });
                  return searchedUsersLength ? (
                    <Collapse size={'middle'} items={items} />
                  ) : (
                    <EmptyState variant="emptyRequest" />
                  );
                })()}
              </>
            )}
          </DrawerContentList>
          <MtsDivider className={classes.divider} />
        </>
      )}
    </>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(Users);
