/**
 * Модель для работы с отчётом "Нарушения"
 */

import { createModel, ModelConfig } from '@rematch/core';
import { saveAs } from 'file-saver';

import { iRootState } from '../store';
import { IOffice, IReportViolations, IViolationsTypes, IEmployeeShort } from './types';
import setStartEndOfDay from '../utils/setStartEndOfDay';

import MapsService from '../services/MapsService';
import UsersService from '../services/UsersService';
import ReportService from '../services/ReportService';

interface IData {
  offices: IOffice[];
  employees: IEmployeeShort[];
  violationsTypes: IViolationsTypes[];
}

interface IList {
  data: IReportViolations[] | null;
  pagesCount: number;
}

interface IState {
  data: IData;
  list: IList;
}

const initialState: IState = {
  data: {
    offices: [],
    employees: [],
    violationsTypes: []
  },
  list: {
    data: null,
    pagesCount: 0
  }
};

const model: ModelConfig<IState> = {
  state: initialState,
  reducers: {
    setData(state: IState, payload: IData): IState {
      return { ...state, data: payload };
    },
    setList(state: IState, payload: IList): IState {
      return { ...state, list: payload };
    },
    resetList(state: IState): IState {
      return {
        ...state,
        list: {
          data: null,
          pagesCount: 0
        }
      };
    },
    reset(): IState {
      return { ...initialState };
    }
  },
  effects: {
    async getData() {
      try {
        const [
          {
            data: { data: offices }
          },
          { data: employees },
          { data: violationsTypes }
        ] = await Promise.all([
          MapsService.getOffices(),
          UsersService.getAllEmployeesShort(),
          ReportService.getViolationsTypes()
        ]);
        this.setData({ offices, employees, violationsTypes });
      } catch (error) {
        return error;
      }
    },
    async getList(params, rootState: iRootState) {
      const cloneFilter = setStartEndOfDay(params, ['from', 'to']);

      try {
        const { data } = await ReportService.getViolations(cloneFilter);

        this.setList(data);
      } catch (error) {
        return error;
      }
    },
    async exportList(params, rootState: iRootState) {
      const { from, to } = params;

      const cloneParams = setStartEndOfDay(params, ['from', 'to']);

      try {
        const { data } = await ReportService.getCsvViolations(cloneParams);

        saveAs(data, `Отчет по нарушениям за период ${from} - ${to}.csv`);
      } catch (error) {
        return error;
      }
    }
  }
};

export const reportViolations: typeof model = createModel(model);
