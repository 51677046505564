/**
 * Модель для работы с отчётом "Треки"
 */

import { createModel, ModelConfig } from '@rematch/core';

import { iRootState } from '../store';
import { IId, IMap, ITrackPoint, ITracks, IEmployeeShort } from './types';
import { IGetTracksFilter as IFilter } from '../services/ReportService';
import getInitialFilter from '../utils/getInitialFilter';
import setStartEndOfDay from '../utils/setStartEndOfDay';

import MapsService from '../services/MapsService';
import UsersService from '../services/UsersService';
import ReportService from '../services/ReportService';

interface IData {
  employees: IEmployeeShort[];
}

interface IState {
  data: IData;
  filter: IFilter;
  list: ITracks | null;
  item: ITrackPoint | null;
  plan: IMap;
  planImg: string;
}

const initialFilter: IFilter = {
  users: [],
  from: new Date().toDateString(),
  timeFrom: '',
  to: new Date().toDateString(),
  timeTo: '',
  PositionAggregationMeters: '0',
  SplitByDays: false,
  SplitOnMapChange: false,
  SplitByEvents: true
};

const initialState: IState = {
  data: {
    employees: []
  },
  filter: getInitialFilter(initialFilter),
  list: null,
  item: null,
  plan: {
    id: '',
    contentId: '',
    name: '',
    preview: '',
    previewFormat: '',
    bbox: [0, 0, 0, 0],
    gbox: [0, 0, 0, 0, 0, 0, 0, 0],
    areas: [],
    beacons: [],
    cameras: [],
    file: null
  },
  planImg: ''
};

const model: ModelConfig<IState> = {
  state: initialState,
  reducers: {
    setData(state: IState, payload: Partial<IData>): IState {
      return { ...state, data: { ...state.data, ...payload } };
    },
    setFilter(state: IState, payload: IFilter): IState {
      return { ...state, filter: { ...state.filter, ...payload } };
    },
    resetFilter(state: IState): IState {
      return { ...state, filter: initialFilter };
    },
    setTrack(state: IState, payload: ITrackPoint): IState {
      return { ...state, item: payload };
    },
    setPlan(state: IState, payload: IMap): IState {
      return { ...state, plan: payload };
    },
    setPlanImg(state: IState, payload: string): IState {
      return {
        ...state,
        planImg: payload
      };
    },
    setTracks(state: IState, payload: ITracks): IState {
      return { ...state, list: payload };
    },
    resetTracks(state: IState): IState {
      return { ...state, list: null };
    },
    resetTrack(state: IState): IState {
      return { ...state, item: null };
    },
    resetPlanImg(state: IState): IState {
      return { ...state, planImg: '' };
    },
    resetPlan(state: IState): IState {
      return { ...state, plan: initialState.plan };
    },
    reset(): IState {
      return { ...initialState, filter: initialFilter };
    }
  },
  effects: {
    async getData() {
      try {
        const { data: employees } = await UsersService.getAllEmployeesShort();
        this.setData({ employees });
      } catch (error) {
        return error;
      }
    },
    async getTracks(_, rootState: iRootState) {
      const { filter } = rootState.reportTracks;

      const cloneFilter = setStartEndOfDay(filter, ['from', 'to', 'timeFrom', 'timeTo']);

      try {
        const { data } = await ReportService.getTracks(cloneFilter);
        // console.log(data);
        this.setTracks(data);
      } catch (error) {
        return error;
      }
    },
    async getPlan(id) {
      try {
        const { data } = await MapsService.get(id);
        // console.log(data);
        this.setPlan(data);
      } catch (error) {
        return error;
      }
    },
    async getPlanImg(id: IId) {
      try {
        const data = await MapsService.getMapImage(id);
        this.setPlanImg(data);
      } catch (error) {
        return error;
      }
    }
  }
};

export const reportTracks: typeof model = createModel(model);
