/**
 * Модель для работы с носимыми устройствами
 */

import { createModel, ModelConfig } from '@rematch/core';
import { v4 as uuidv4 } from 'uuid';

import { iRootState } from '../store';
import { IDevice, IProtectionType, IShortUser, IId } from './types';

import DeviceService, { IUpdateOrCreateBody } from '../services/DeviceService';
import ProtectionsService from '../services/ProtectionsService';
import UsersService from '../services/UsersService';

export interface IData {
  types: IProtectionType[];
  users: IShortUser[];
}

interface IList {
  data: IDevice[] | null;
  pagesCount: number;
}

export interface IForm {
  uid: IId;
  isValidate: boolean;
}

interface IForms {
  list: IForm[];
  isSubmit: boolean;
}

interface IState {
  data: IData;
  list: IList;
  item: IUpdateOrCreateBody | null;
  forms: IForms;
}

const initialState: IState = {
  data: {
    types: [],
    users: []
  },
  list: {
    data: null,
    pagesCount: 0
  },
  item: null,
  forms: {
    list: [
      {
        uid: uuidv4(),
        isValidate: false
      }
    ],
    isSubmit: false
  }
};

const model: ModelConfig<IState> = {
  state: initialState,
  reducers: {
    setData(state: IState, payload: IData): IState {
      return { ...state, data: payload };
    },
    setList(state: IState, payload: IList): IState {
      return { ...state, list: payload };
    },
    setItem(state: IState, payload: IUpdateOrCreateBody): IState {
      return { ...state, item: payload };
    },
    addForm(state: IState): IState {
      return {
        ...state,
        forms: { ...state.forms, list: [...state.forms.list, { uid: uuidv4(), isValidate: false }] }
      };
    },
    deleteForm(state: IState, uid: IId): IState {
      return {
        ...state,
        forms: {
          ...state.forms,
          list: state.forms.list.filter(item => item.uid !== uid)
        }
      };
    },
    editForm(state: IState, payload: IForm) {
      const { uid } = payload;

      return {
        ...state,
        forms: {
          ...state.forms,
          list: state.forms.list.map(item => (item.uid === uid ? payload : item))
        }
      };
    },
    setSubmitForms(state: IState, payload: boolean): IState {
      return { ...state, forms: { ...state.forms, isSubmit: payload } };
    },
    reset(): IState {
      return { ...initialState };
    }
  },
  effects: {
    async getData() {
      try {
        const [
          {
            data: { data: types }
          },
          { data: users }
        ] = await Promise.all([
          ProtectionsService.getTypeAll({ classType: 'WEAR', pageSize: 100 }),
          UsersService.getAllEmployeesShort()
        ]);

        this.setData({ types, users });
      } catch (error) {
        return error;
      }
    },
    async getList(params, rootState: iRootState) {
      try {
        const { data } = await DeviceService.getAll(params);

        this.setList(data);
      } catch (error) {
        return error;
      }
    },
    async getItem(id: IId) {
      try {
        const { data } = await DeviceService.get(id);

        this.setItem(data);
      } catch (error) {
        return error;
      }
    },
    async updateOrCreate(body: IUpdateOrCreateBody) {
      try {
        await DeviceService.updateOrCreate(body);
      } catch (error) {
        return error;
      }
    },
    async unbind(id: IId) {
      try {
        await DeviceService.unbind(id);
      } catch (error) {
        return error;
      }
    },
    async remove(id: IId) {
      try {
        await DeviceService.delete(id);
      } catch (error) {
        return error;
      }
    }
  }
};

export const equipmentDevices: typeof model = createModel(model);
