import React, { FC } from 'react';
import { MtsInput } from '@mts-ds/components-react';

import { checkDate } from './utils';

import FormGroup, { IPropsText as IFormGroupTextProps } from '../components/FormGroup';

export type IProps = IFormGroupTextProps & typeof MtsInput.defaultProps;

const Input: FC<IProps> = ({
  label,
  tooltip,
  description,
  valid,
  clear,
  isHideDescription,
  onMtsChange,
  ...props
}) => {
  // Изменяем дату по достижению полного формата
  // tslint:disable-next-line: no-shadowed-variable
  const handleMtsChange = (onMtsChange: any) => (event: any) => {
    const { value, type } = event.target;

    if (checkDate(value, type)) {
      onMtsChange(event);
    }
  };

  return (
    <FormGroup
      label={label}
      description={description}
      tooltip={tooltip}
      valid={valid}
      isHideDescription
    >
      <MtsInput
        {...(onMtsChange && { onMtsChange: handleMtsChange(onMtsChange) })}
        {...(clear && props.value && { clear: true })}
        {...props}
      />
    </FormGroup>
  );
};

export default Input;
