import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';

import { Dispatch, iRootState } from '../../../store';
import { paths } from '../../Root/paths';
import { useAsync, useQueryParams } from '../../../hooks';
import Drawer from '../../../components/Drawer';

import Spinner from '../../../components_/Spinner';
import EmptyState from '../../../components_/EmptyState';

import DrawerContent from '../../../components_/Drawer/DrawerContent';
import { MtsBreadcrumb, MtsBreadcrumbItem, MtsButton, MtsLabel } from '@mts-ds/components-react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import FormRow from '../../../components_/Form/FormRow';
import FormCol from '../../../components_/Form/FormCol';
import InputWithDate from '../../../components_/Fields/DatePicker/inputWithDate/inputWithDate';
import Dropdown from '../../../components_/Fields/Dropdown';
import Typography from '../../../components_/Typography';
import Item from './item';
import Map from './Map';
import { ExpansionPanelDetails, ExpansionPanelSummary, Grid } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import { ReactComponent as ArrowDown } from '../../../assets_/icons/arrowDown.svg';

import FormDoubleField from '../../../components_/Form/FormDoubleField';
import TimePicker from '../../../components_/Fields/TimePicker';
import Switch from '../../../components_/Fields/Switch';
import Input from '../../../components_/Fields/Input';
import AntSelect from '../../../components_/Fields/AntSelect';

const mapState = (state: iRootState) => ({
  data: state.reportTracks.data,
  filter: state.reportTracks.filter,
  tracks: state.reportTracks.list,
  track: state.reportTracks.item
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getData: d.reportTracks.getData,
    setFilter: d.reportTracks.setFilter,
    resetFilter: d.reportTracks.resetFilter,
    getTracks: d.reportTracks.getTracks,
    resetTracks: d.reportTracks.resetTracks,
    resetTrack: d.reportTracks.resetTrack
  };
};

const useStyles = makeStyles(({ spacing }) => ({
  breadcrumb: {
    marginTop: spacing(2),
    marginBottom: spacing(1)
  },
  h1: {
    marginTop: spacing(2),
    marginBottom: spacing(2)
  },
  icon: {
    backgroundColor: 'white',
    boxShadow: '0px 8px 24px #E7EAFA',
    borderRadius: '50%',
    width: 64,
    height: 64,
    marginBottom: spacing(2)
  },
  button: {
    marginTop: spacing(2)
  },
  desc: {
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
    color: '#626C77'
  },
  filter: {
    marginBottom: 10
  }
}));

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export type IProps = connectedProps;

const HeatMap: FC<IProps> = ({
  getData,
  setFilter,
  resetFilter,
  getTracks,
  resetTracks,
  resetTrack,
  data,
  filter,
  tracks,
  track
}) => {
  const [runGetData, isLoadingGetData, isGetDataLoadingError] = useAsync(getData, true);
  const [runGetTracks, isLoadingGetTracks, isGetTracksLoadingError] = useAsync(getTracks);
  const history = useHistory();
  const classes = useStyles();
  const { employees } = data;
  const { setQueryParams } = useQueryParams();
  const {
    users,
    from,
    to,
    timeFrom,
    timeTo,
    PositionAggregationMeters,
    SplitByDays,
    SplitOnMapChange,
    SplitByEvents
  } = filter;
  const handleChange = (event: any) => {
    const { name, value, checked, tagName } = event.target;
    if (tagName && tagName === 'MTS-SWITCH') {
      setFilter({ [name]: checked });
    } else {
      setFilter({ [name]: value });
    }
  };

  const handleClick = () => {
    resetTrack();
    runGetTracks();
    setQueryParams(filter);
  };

  useEffect(() => {
    runGetData();

    return () => {
      resetTrack();
    };
  }, []);

  const handleBreadcrumbItemClick = (url: string) => () => {
    history.push(url);
  };

  const isLoading = isLoadingGetData || isLoadingGetTracks;
  const isLoadingError = isGetDataLoadingError || isGetTracksLoadingError;

  const employeeOptions = employees.map((employee: { id: any; fullName: string }) => ({
    label: employee.fullName,
    value: employee.id
  }));

  const title = 'Треки';

  return (
    <>
      {!isLoading && !isLoadingError && (
        <>
          {!track && (
            <>
              <Grid item xs container direction="column" justify="center" alignItems="center">
                {/*<Grid*/}
                {/*  container*/}
                {/*  justify="center"*/}
                {/*  alignItems="center"*/}
                {/*  className={classes.icon}*/}
                {/*></Grid>*/}
                <Typography variant="p2Regular" color="text-tertiary" className={classes.desc}>
                  Отслеживайте движение сотрудников за выбранный период времени.
                  <br />
                  Выберите дату или сотрудника, чтобы посмотреть список треков и перейти в нужный
                  трек.
                </Typography>
              </Grid>
            </>
          )}
          {/* {heatMap && <Image heatMap={heatMap} />} */}
          {track && <Map track={track} />}
        </>
      )}
      <Drawer>
        <DrawerContent>
          <MtsBreadcrumb size="s" className={classes.breadcrumb}>
            {[{ name: 'Отчёты', url: paths.reports.root }, { name: title }].map(
              ({ name, url }, index, array) => (
                <MtsBreadcrumbItem
                  key={index}
                  active={index === array.length - 1}
                  {...(url && { onClick: handleBreadcrumbItemClick(url) })}
                >
                  {name}
                </MtsBreadcrumbItem>
              )
            )}
          </MtsBreadcrumb>

          <Typography className={classes.h1} variant="h1Bold">
            Треки
          </Typography>

          <FormRow className={classes.filter}>
            <FormCol xs={12}>
              {/*<BaseInput name="from" label="Дата от" type="date" value={from} onChange={handleChange} />*/}
              <FormDoubleField
                label="Дата от"
                separator=""
                left={
                  <InputWithDate
                    size={'s'}
                    name="from"
                    value={from}
                    maxDate={new Date()}
                    onChange={handleChange}
                  />
                }
                right={<TimePicker name="timeFrom" value={timeFrom} onChange={handleChange} />}
              />
              {/*<InputWithDate*/}
              {/*  name="trackFrom"*/}
              {/*  label="Дата от"*/}
              {/*  value={trackFrom}*/}
              {/*  maxDate={new Date()}*/}
              {/*  onChange={handleChange}*/}
              {/*/>*/}
            </FormCol>
            <FormCol xs={12}>
              {/*<BaseInput name="to" label="Дата до" type="date" value={to} onChange={handleChange} />*/}
              <FormDoubleField
                label="Дата до"
                separator=""
                left={
                  <InputWithDate
                    size={'s'}
                    name="to"
                    value={to}
                    maxDate={new Date()}
                    onChange={handleChange}
                  />
                }
                right={<TimePicker name="timeTo" value={timeTo} onChange={handleChange} />}
              />
              {/*<InputWithDate*/}
              {/*  name="trackTo"*/}
              {/*  label="Дата до"*/}
              {/*  value={trackTo}*/}
              {/*  maxDate={new Date()}*/}
              {/*  onChange={handleChange}*/}
              {/*/>*/}
            </FormCol>
            <FormCol xs={12}>
              <AntSelect
                name="users"
                // size='large'
                label="Сотрудник (необязательно)"
                placeholder="Выберите значение"
                options={employeeOptions}
                optionFilterProp="label"
                mode="multiple"
                allowClear
                value={users}
                onChange={handleChange}
              />
              {/*<Dropdown*/}
              {/*  name="users"*/}
              {/*  label="Сотрудник (необязательно)"*/}
              {/*  placeholder="Выберите значение"*/}
              {/*  groupOptions={brigadeOptions}*/}
              {/*  multiple*/}
              {/*  value={users}*/}
              {/*  onMtsChange={handleChange}*/}
              {/*/>*/}
            </FormCol>
            <FormCol xs={12}>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ArrowDown style={{ width: 24, height: 24 }} />}>
                  <MtsLabel>Параметры трека (необязательно)</MtsLabel>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <FormRow>
                    <FormCol xs={12}>
                      <MtsLabel>{'Разрывать новый трек:'}</MtsLabel>
                    </FormCol>

                    <FormCol xs={3}>
                      <Switch
                        checked={SplitByEvents}
                        key="SplitByEvents"
                        name="SplitByEvents"
                        onMtsChange={handleChange}
                      />
                    </FormCol>

                    <FormCol xs={9}>
                      <MtsLabel>{'При запуске устройтсва'}</MtsLabel>
                    </FormCol>
                    <FormCol xs={3}>
                      <Switch
                        checked={SplitByDays}
                        key="SplitByDays"
                        name="SplitByDays"
                        onMtsChange={handleChange}
                      />
                    </FormCol>
                    <FormCol xs={9}>
                      <MtsLabel>{'При смене суток'}</MtsLabel>
                    </FormCol>
                    <FormCol xs={3}>
                      <Switch
                        checked={SplitOnMapChange}
                        key="SplitOnMapChange"
                        name="SplitOnMapChange"
                        onMtsChange={handleChange}
                      />
                    </FormCol>
                    <FormCol xs={9}>
                      <MtsLabel>{'При смене плана'}</MtsLabel>
                    </FormCol>
                    <FormCol xs={12}>
                      <Input
                        tooltip="Интервал между точками
                         позиции сотрудника для слияния их в одну"
                        value={PositionAggregationMeters}
                        type="number"
                        key="PositionAggregationMeters"
                        name="PositionAggregationMeters"
                        label="Радиус агрегации точек в метрах"
                        onMtsChange={handleChange}
                      />

                      {/*<Switch*/}
                      {/*  key="PositionAggregationMeters"*/}
                      {/*  name="PositionAggregationMeters"*/}
                      {/*  onMtsChange={handleChange}*/}
                      {/*/>*/}

                      {/*<Typography variant="p2Regular" color="text-primary">*/}
                      {/*  {'Порог для аггрегации позиции в метрах'}*/}
                      {/*</Typography>*/}
                    </FormCol>
                  </FormRow>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </FormCol>
            <FormCol xs="auto">
              <MtsButton loading={isLoadingGetTracks} onClick={handleClick}>
                Применить
              </MtsButton>
            </FormCol>
          </FormRow>
          {isLoading && <Spinner />}
          {isLoadingError && <EmptyState variant="serverError" />}
          {!isLoading && !isLoadingError && (
            <>
              {!tracks || (!tracks.tracks.length && <EmptyState variant="emptyRequest" />)}
              {tracks && tracks.tracks.length !== 0 && (
                <>
                  {tracks.tracks.map((item: any) => (
                    <Item key={item.userId} track={item} />
                  ))}
                </>
              )}
            </>
          )}
        </DrawerContent>
      </Drawer>
    </>
    // <Content
    //   breadcrumb={[{ name: 'Отчёты', url: paths.reports.root }, { name: title }]}
    //   title={title}
    // >
    //   {!isLoadingGetData && !isGetDataLoadingError && (
    //     <>
    //       {!isLoading && !isLoadingError && (
    //         <>
    //           {!heatMap && <EmptyState variant="generateReport" />}
    //           {/* {heatMap && <Image heatMap={heatMap} />} */}
    //           {heatMap && <Map heatMap={heatMap} />}
    //         </>
    //       )}
    //     </>
    //   )}
    //   {isLoading && <Spinner />}
    //   {isLoadingError && <EmptyState variant="serverError" />}
    // </Content>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(HeatMap);
