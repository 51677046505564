import React, { FC, ReactNode } from 'react';
import { Form as FinalForm, FormRenderProps } from 'react-final-form';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsButton } from '@mts-ds/components-react';
import {
  ButtonSizes,
  ButtonFormats,
  ButtonColors
} from '@mts-ds/components/dist/types/components/button/enums';

import { IForm } from '../../../../models/equipmentProtections';
import { IUpdateOrCreateBody } from '../../../../services/ProtectionsService';
import { required } from '../../../../utils/validation';
import useRequestHandler from '../../../../hooks/useRequestHandler';

import Typography from '../../../../components_/Typography';
import Icon from '../../../../components_/Icon';
import FormBase from '../../../../components_/Form';
import FormRow from '../../../../components_/Form/FormRow';
import FormCol from '../../../../components_/Form/FormCol';
import FormInput from '../../../../components_/Form/FormInput';
import FormDropdown from '../../../../components_/Form/FormDropdown';

import { IProps as IFormProps } from './';
import FormDatePicker from '../../../../components_/Form/FormDatePicker';

const useStyles = makeStyles(({ spacing }) => ({
  title: {
    margin: spacing(5, 0, 2)
  },
  deleteFormButton: {
    marginLeft: spacing(1.5)
  }
}));

type IValues = IUpdateOrCreateBody;

type IRender = (props: FormRenderProps<IValues>) => ReactNode;

interface IProps {
  index: number;
  form: IForm;
  data: IFormProps['data'];
  item: IValues | null;
  isCreate: boolean;
  isEdit: boolean;
  isCopy: boolean;
  isSubmit: boolean;
  deleteForm: any;
  editForm: any;
  setSubmitForms: any;
  onSubmitAction: any;
  onCancel: any;
}

const defaultValues = {
  id: 0,
  number: '',
  classId: '',
  numberType: '',
  mappingType: '',
  userId: null,
  bestBefore: null
};

const Form: FC<IProps> = ({
  index,
  form,
  data,
  item,
  isCreate,
  isEdit,
  isCopy,
  isSubmit,
  deleteForm,
  editForm,
  setSubmitForms,
  onSubmitAction,
  onCancel
}) => {
  const { types, technology, users } = data;

  const classes = useStyles();
  const requestHandler = useRequestHandler();

  const initialValues: IValues = {
    ...defaultValues,
    ...((isEdit || isCopy) && {
      ...item,
      bestBefore: item && item.bestBefore && moment(item.bestBefore).format('YYYY-MM-DD')
    }),
    ...(isCopy && {
      id: defaultValues.id,
      userId: defaultValues.userId,
      number: defaultValues.number
    })
  };

  const technologyOptions = technology.map(({ numberType }) => ({
    id: numberType,
    name: numberType
  }));
  const userOptions = users.map(({ id, fullName }) => ({
    id,
    name: fullName
  }));

  const userIdValidate = (value: string | string[], values: object) => {
    const { mappingType } = values as IValues;

    if (mappingType === 'USER_PERMANENT') {
      return required(value);
    }
  };

  const onSubmit = async (values: IValues) => {
    const body = { ...values };

    if (body.bestBefore) {
      body.bestBefore = moment(body.bestBefore)
        .endOf('day')
        .format();
    }

    const error = await onSubmitAction(body);

    requestHandler({
      error,
      entity: 'protection',
      onSuccess: onCancel,
      isCreate,
      isEdit,
      isCopy
    });

    setSubmitForms(false);
  };

  const render: IRender = ({
    handleSubmit,
    values: { mappingType },
    hasValidationErrors,
    pristine,
    form: { change }
  }) => {
    const isValidate = !(hasValidationErrors || pristine);

    if (mappingType === 'USER_TEMPORARY') {
      change('userId', defaultValues.userId);
    }

    if (form.isValidate !== isValidate) {
      editForm({ uid: form.uid, isValidate });
    }

    if (isSubmit) {
      handleSubmit();
    }

    const titleIndex = index + 1;
    const title = (
      <Grid container alignItems="center">
        Новое СИЗ
        {titleIndex > 1 && (
          <>
            ({titleIndex})
            <MtsButton
              size={'xs' as ButtonSizes}
              format={'icon-round' as ButtonFormats}
              color={'secondary' as ButtonColors}
              onClick={() => deleteForm(form.uid)}
              className={classes.deleteFormButton}
            >
              <Grid container alignItems="center">
                <Icon name="minus" size="s" />
              </Grid>
            </MtsButton>
          </>
        )}
      </Grid>
    );

    return (
      <>
        <Typography variant="h3Medium" className={classes.title}>
          {isCreate && title}
        </Typography>
        <FormBase>
          <FormRow>
            <FormCol>
              <FormInput
                name="number"
                label="Метка"
                placeholder="Введите метку (01:17:C5:9B:33:D6)"
                useFieldConfig={{ validate: required }}
              />
            </FormCol>
            <FormCol>
              <FormDropdown
                name="classId"
                label="Тип"
                placeholder="Выберите тип"
                options={types}
                useFieldConfig={{ validate: required }}
              />
            </FormCol>
            <FormCol>
              <FormDropdown
                name="numberType"
                label="Технология"
                placeholder="Выберите технологию"
                options={technologyOptions}
                useFieldConfig={{ validate: required }}
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <FormDropdown
                name="mappingType"
                label="Тип использования"
                placeholder="Выберите тип использования"
                options={[
                  {
                    id: 'USER_PERMANENT',
                    name: 'Постоянный'
                  },
                  {
                    id: 'USER_TEMPORARY',
                    name: 'Временный'
                  }
                ]}
                useFieldConfig={{ validate: required }}
              />
            </FormCol>
            <FormCol>
              <FormDropdown
                name="userId"
                label="Владелец"
                placeholder="Выберите владельца"
                options={userOptions}
                disabled={mappingType !== 'USER_PERMANENT'}
                useFieldConfig={{ validate: userIdValidate }}
              />
            </FormCol>
            <FormCol>
              <FormDatePicker name="bestBefore" label="Срок эксплуатации до (необязательно)" />
              {/*<FormBaseInput*/}
              {/*  name="bestBefore"*/}
              {/*  label="Срок эксплуатации до (необязательно)"*/}
              {/*  type="date"*/}
              {/*/>*/}
            </FormCol>
          </FormRow>
        </FormBase>
      </>
    );
  };

  return <FinalForm initialValues={initialValues} onSubmit={onSubmit} render={render} />;
};

export default Form;
