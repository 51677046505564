import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Dispatch, iRootState } from '../../../../../../store';

import Spinner from '../../../../../../components_/Spinner';
import NotificationDrawerList from '../../../../../../components_/Notification/NotificationDrawerList';

import Card from './Card';
import Attributes from './Attributes';
import isSatisfied from '../../../../../../utils/isSatisfied';
import { guids } from '../../../../../Root/paths';
import Users from './UserInArea';

const mapState = (state: iRootState) => ({
  isLoadingArea: state.loading.effects.infrastructureAreas.getArea,
  isLoadingData: state.loading.effects.infrastructureAreas.getData,
  isLoadingNotifications: state.loading.effects.infrastructureAreas.getNotifications,
  area: state.infrastructureAreas.item,
  data: state.infrastructureAreas.data,
  notifications: state.infrastructureAreas.notifications,
  rights: state.securable.data
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getArea: d.infrastructureAreas.getArea,
    getData: d.infrastructureAreas.getData,
    getNotifications: d.infrastructureAreas.getNotifications,
    setBoxEditMode: d.infrastructureMap.setBoxEditMode,
    setCircleEditMode: d.infrastructureMap.setCircleEditMode,
    setPolygonEditMode: d.infrastructureMap.setPolygonEditMode
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = connectedProps;

interface IParams {
  id: string;
}

const AreaShow: FC<IProps> = ({
  getArea,
  getData,
  getNotifications,
  setBoxEditMode,
  setCircleEditMode,
  setPolygonEditMode,
  isLoadingArea,
  isLoadingData,
  isLoadingNotifications,
  area,
  data,
  notifications,
  rights
}) => {
  const { id } = useParams<IParams>();

  useEffect(() => {
    getArea(id);
    getData();
    getNotifications(id);
  }, [id]);

  const isLoading = isLoadingArea || isLoadingData || isLoadingNotifications;

  return (
    <>
      <Card
        isLoading={isLoading}
        editAccess={isSatisfied(
          { ...{ uid: guids.map.office.areas.edit() }, ...{ action: 'Visibility' } },
          rights
        )}
        area={area}
        setBoxEditMode={setBoxEditMode}
        setCircleEditMode={setCircleEditMode}
        setPolygonEditMode={setPolygonEditMode}
      />
      {isLoading && <Spinner />}
      {!isLoading && area && (
        <>
          <Attributes area={area} data={data} />
          <Users areaId={id} />
          <NotificationDrawerList notifications={notifications} />
        </>
      )}
    </>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(AreaShow);
